<template>
	<div class="gg-container">
		<div class="search-container">
			
			<!--按钮样式-->
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handleBtn01">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">打开抽屉栅格</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handleBtn01">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">打开抽屉栅格</span>
					</el-button>
				</div>
			</div>
		</div>
		
		
		<!----------------抽屉弹出框-------------->
		<el-drawer
			title=""
			:visible.sync="drawerVisible"
			direction="rtl"
			v-loading="listLoading"
			:before-close="handleCloseDrawer"
			@closeDrawer="handleCloseDrawer"
			size="76%"
			:with-header="false"
		>
			<drawTwoComponent
				@closeDrawer="handleCloseDrawer"
				@getList="getList"
				:info="info"
				v-if="drawerVisible"></drawTwoComponent>
		</el-drawer>
	</div>
</template>

<script>
import drawTwoComponent from './components/drawTwoComponent';
export default {
  name: "DrawTwo",
  components: {
    drawTwoComponent,
  },
  created () {
  },
  data () {
    return {
      drawerVisible: false,
      listLoading: false,
      info: {
        a:1,
      },
    }
  },
  methods: {
    getList() {
      console.log('getList触发')
    }, // 如果draw数据更新,回调getList()刷新数据
    handleBtn01 () {
      this.drawerVisible = true;
    },
    handleCloseDrawer() {
      this.getList() // 关闭draw, 刷新list数据
      this.drawerVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
	/*抽屉高度*/
	::v-deep .el-drawer {
		bottom: 0 !important;
	}
	
	::v-deep .el-drawer__header {
		padding: 0;
		margin: 0;
	}
	
	::v-deep .el-drawer.rtl {
		overflow: visible;
		height: 91vh;
		bottom: 0;
		margin-top: 9vh;
		position: absolute;
	}
</style>
